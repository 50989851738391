import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './langs';

Vue.use(VueI18n);

// 获取浏览器默认语言
function getDefaultLanguage() {
    const language = (navigator.language || navigator.browserLanguage).toLowerCase();
    return language.indexOf('zh') >= 0 ? 'cn' : 'en'
}

const i18n = new VueI18n({
    locale: localStorage.lang || getDefaultLanguage(),
    messages
});

export default i18n;