import * as CryptoJS from 'crypto-js';

export default {
    encrypt: (data, keyCode, ivCode) => {
        const key = CryptoJS.enc.Utf8.parse(keyCode || 'sd7JD8sKzc8MHdf8');
        const iv = CryptoJS.enc.Utf8.parse(ivCode || '29JlOd8SCdk82CFd');
        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        return `${encrypted}`;
    },
    decrypt: (data, keyCode, ivCode) => {
        const key = CryptoJS.enc.Utf8.parse(keyCode || 'sd7JD8sKzc8MHdf8');
        const iv = CryptoJS.enc.Utf8.parse(ivCode || '29JlOd8SCdk82CFd');
        const decrypted = CryptoJS.AES.decrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    },
    base64Encrypt: (data) => {
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data))
    },
    base64Decrypt: (data) => {
        return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8)
    }
}