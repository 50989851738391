// 从localStorage中获取初始值
export default {
    namespaced: true,
    state: {
        device: {
            channelNum:16,
            tz: 27,
            deviceId: "",
            deviceTime:"1675906595",
            list: 'BlackNameListMode',
            wsMode: 1,
            firmwareVer: '', // 固件版本
            // smartType: -1 // 0-人脸 1-混合
          },
        antLoading:false, //控制loading
        centitle:"",    //loading的文字
        wsMode: 1,
        // IP
		HOSTNAME: window.location.hostname.includes("localhost")
        ? "10.171.18.74"
        : window.location.hostname,

    HOST: window.location.host.includes("localhost")
        ? "10.171.18.74"
        : window.location.host,
        UPDATE_DEVICE : 'UPDATE_DEVICE'
    },
    actions: {
        async updateLoading({ commit }, currDbSource) {
            console.warn('updateLoading');
            commit('saveCurrDbSource', currDbSource)
        },
    },
    mutations: {
        saveCurrDbSource(state,currDbSource){
            state.antLoading = currDbSource[0];
            state.centitle = currDbSource[1];
        },
        
        UPDATE_DEVICE(state, device) {
            if(device.wsMode != undefined){
              state.device.wsMode = device.wsMode
            }
            localStorage.setItem('device', JSON.stringify(state.device))
          },
    },
    getters:{
        getDevice(state) {
            return state.device
          },
    }
}
