import Vue from 'vue';
import Router from 'vue-router';
// 路由数据
import routes from './routes'
import store from '@/store/index'
import i18n from '@/i18n';

Vue.use(Router)

const router = new Router({
  routes
})

router.afterEach(to => {
  // 多页控制 打开新的页面
  store.dispatch('megCube/page/open', to)
})
// 如果在录制中切换路由，需要关闭视频录制
router.beforeEach((to, from, next) => {
  // 切换路由时检测是否
  if (to.name !== 'activation' && to.name !== 'login') {
      if (!sessionStorage.getItem("sessionID")) {
          if (to.name == 'activation') {
              return next({ name: 'activation' })
          } else {
              return next({ name: 'login' })
          }
      }
  }
  if (from.path == '/preview') {
    if (store.state.megCube.video.listLength.length > 0) {
      Vue.prototype
        .$confirm({
          title: i18n.t('message.library.tips'),
          content: i18n.t('message.structure.videoDownTips1'),
          cancelText: i18n.t('message.common.cancel'),
          okText: i18n.t('message.structure.overDown'),
          onOk: () => {
            store.dispatch('megCube/video/updateIsNeedOver', true);
            setTimeout(() => {
              next();
            }, 0);
          },
          onCancel: () => {
            return;
          }
        })
    } else {
      next();
    }
  } else {
    next();
  }
});



export default router
