/*
megConnect协议接口
*/
import axios from 'axios';

// 登录鉴权挑战
export const loginChallenge = params => {
	return axios.get('/auth/login/challenge', { params }).then(res => res.data);
};

// 登录接口
export const login = params => {
	return axios.post(`/auth/login`, params).then(res => res.data);
};

// 登录连接保活
export const keepAlive = params => {
	return axios.put(`/login_manager/keep_alive`, params).then(res => res.data);
};

// 登出设备
export const logout = params => {
	return axios.get('/auth/logout', { params }).then(res => res.data);
};

// 查询人员分组
export const getGroups = () => {
	return axios.post('/face_manager/groups/query').then(res => res.data);
};

// 查询人员信息
export const getPersonInfo = params => {
	return axios.post('/face_manager/person/query', params).then(res => res.data);
};
// 获取入库失败人员列表
export const getFailList = params => {
	return axios.post('/face_manager/fail_list/query', params).then(res => res.data);
};
// 清空入库失败人员列表
export const deleteFailList = params => {
	return axios.delete('/face_manager/fail_list', { data: {clear_all: params.clear_all} }).then(res => res.data)
}

// 查询布控信息
export const getMonitorList = params => {
	return axios.post('/intelli_manager/monitor_list', params).then(res => res.data);
};

// 批量删除人员
export const deletePerson = params => {
	return axios.delete(`/face_manager/person`, { data: { force: params.force, all: params.all, person_id_list: params.person_id_list } }).then(res => res.data);
};

// 创建人员
export const postNewPerson = params => {
	return axios.post('/face_manager/person', params).then(res => res.data);
};

// 修改人员
export const putEditPerson = params => {
	return axios.put('/face_manager/person', params).then(res => res.data);
};

// 开始批量创建人员
export const postStartBatchAddPerson = params => {
	return axios.post('/face_manager/add_person_batch/start', params).then(res => res.data);
};

// 停止批量创建人员
export const postStopBatchAddPerson = params => {
	return axios.post('/face_manager/add_person_batch/stop', params).then(res => res.data);
};

// 查询批量创建人员进度
export const postQueryProgress = params => {
	return axios.post('/face_manager/add_person_batch_progress/query', params).then(res => res.data);
};

// 创建人员分组
export const postCreateGroup = params => {
	return axios.post('/face_manager/group', params).then(res => res.data);
};

// 修改人员分组
export const putEditGroup = params => {
	return axios.put('/face_manager/group', params).then(res => res.data);
};

// 删除人员分组
export const deleteGroup = params => {
	return axios.delete(`/face_manager/group`, { data: { group_id: params.group_id, force: params.force, person_remove_type: params.person_remove_type } }).then(res => res.data);
};

// 查询分组绑定人员列表
export const postQueryPersonByGroup = params => {
	return axios.post('/face_manager/person_bind/query', params).then(res => res.data);
};

// 解绑人员与分组
export const deletePersonFromGroup = params => {
	return axios.delete(`/face_manager/person_bind`, { data: { person_id: params.person_id, face_groups: params.face_groups } }).then(res => res.data);
};

// 绑定人员与分组
export const postBindPersonAndGroup = params => {
	return axios.post('/face_manager/person_bind', params).then(res => res.data);
};

// 修改人员绑定分组
export const putChangePersonBindGroup = params => {
	return axios.put(`/face_manager/person_bind`, params).then(res => res.data);
};

// 操作日志查询
export const logQuery = params => {
	return axios.post(`/device_maintenance/log`, params).then(res => res.data);
};
// 操作日志导出
export const getLogFile = params => {
	return axios.post(`/device_maintenance/export_log_file`, params).then(res => res.data);
};
// 调试日志下载
export const getDebug  = (params) => {
	const uploadaxios = axios.create({
		headers:{
			'Range':'bytes=0-'
		},
		responseType: 'blob'
	})
	return uploadaxios.get(`/device_maintenance/file_get`, {params} ).then(res => res.data);
	
};
// 操作数据表
export const putDataSheet = params => {
	return axios.put(`/database_manager/table`, params).then(res => res.data);
};
// 停止日志导出
// export const deleteLogFile = () => {
//     return axios.delete(`//device_maintenance/export_log_file`,).then((res) => res.data);
// };
// 订阅报警
export const alarmSubscribeStream = () => {
	return axios.post('/device_alarm/subscribe_stream').then(res => res.data);
};

// 退订报警
export const alarmUnsubscribeStream = params => axios.delete('/device_alarm/subscribe_stream', { data: params }).then(res => res.data);

// 订阅、退订报警类型
export const subscribeAlarmType = params => {
	return axios.put('/device_alarm/subscribe_alarm_type', params).then(res => res.data);
};

// 订阅视频流
export const subscribeStream = params => axios.post('/media_video/subscribe_stream', params).then(res => res.data);

// 退订视频流
export const unsubscribeStream = params => axios.delete('/media_video/subscribe_stream', { data: params }).then(res => res.data);

// 获取布控信息
export const queryMonitor = params => axios.post('/intelli_manager/monitor_list', params).then(res => res.data);

// 获取报警类型能力集
export const getAlarmCap = () => {
	return axios.get('/device_alarm/alarm_cap').then(res => res.data);
};
// 查询报警记录
export const getAlarmRecord = params => {
	return axios.post('/device_alarm/alarm_history', params).then(res => res.data);
};

// 删除报警记录
export const deleteAlarmRecord = params => {
	return axios.delete('/device_alarm/alarm_history', { data: params }).then(res => res.data);
};

// 获取统计信息
export const postStatistics = params => {
	return axios.post('/statistic_manager/info', params).then(res => res.data);
};

// 清空统计信息
export const deleteStatistics = params => {
	return axios.delete('/statistic_manager/info', { data: params }).then(res => res.data);
};

// 设置报警记录配置参数
export const putAlarmConfig = params => {
	return axios.put('/device_alarm/alarm_history_config', params).then(res => res.data);
};

// 获取报警记录配置参数
export const getAlarmConfig = () => {
	return axios.get('/device_alarm/alarm_history_config').then(res => res.data);
};

// 算法仓上传接口
export const algUpload = params => {
	return axios.post('/intelli_manager/alg_warehouse/apply', params).then(res => res.data);
};

// 中止算法仓操作
export const stopAlgOperation = params => {
	return axios.delete(`/intelli_manager/alg_warehouse/operation`, { data: params }).then(res => res.data);
};

// 查询算法仓信息
export const getAlgInfo = params => {
	return axios.post('/intelli_manager/alg_warehouse/packet_list', params).then(res => res.data);
};

// 请求算法仓操作
export const applyAlgOperation = params => {
	return axios.put(`/intelli_manager/alg_warehouse/operation`, params).then(res => res.data);
};

// 查询算法仓操作结果
export const getAlgOperationInfo = params => {
	return axios.post(`/intelli_manager/alg_warehouse/operation`, params).then(res => res.data);
};
// 查询算法仓能力集
// export const postCapability = (params) => {
//     return axios.get(`/intelli_manager/algo_cfg_cap`, params).then((res) => res.data);
// };

// 查询算法仓卡片能力集
export const postWarehouseCard = params => {
	return axios.post('/intelli_manager/alg_warehouse/card_cap', params).then(res => res.data);
};

// 获取算法仓config配置
export const getAlgoConfig = params => {
	const { path } = params;
	return axios.get(path);
};

// 1:N人脸识别
export const postOneToN = params => {
	return axios.post(`/face_manager/search_face`, params).then(res => res.data);
};

// 1:N人脸识别
export const postOneToOne = params => {
	return axios.post(`/face_manager/compare_face`, params).then(res => res.data);
};

// 提取人脸特征值
export const postFeatureExtraction = params => {
	return axios.post(`/face_manager/face_feature`, params).then(res => res.data);
};

// 查询静态分析器
export const getAnalyzer = params => {
	return axios.post(`/intelli_manager/static_analyzer`, params).then(res => res.data);
};
// 智能数据解析 
export const intelDataParse = params => {
	return axios.put(`/intelli_manager/data_parse`, params).then(res => res.data);
};
// ------------------------------------------ 联动计划---------------------------------
// 查询联动规则
export const postLinkageQuery = params => {
	return axios.post(`/trigger_rule/rules`, params).then(res => res.data);
};
// 添加联动规则
export const postLinkageAdd = params => {
	return axios.post(`/trigger_rule/rule`, params).then(res => res.data);
};
// 删除联动规则
export const deleteLinkageOut = params => {
	return axios.delete(`/trigger_rule/rule`,  { data: params }).then(res => res.data);
};
// 编辑联动规则
export const putLinkageEdit = params => {
	return axios.put(`/trigger_rule/rule`, params).then(res => res.data);
};



// ------------------------------------------ 容器管理---------------------------------
// 获取容器配置
export const postContainerListApi = params => {
	return axios.post(`/container_manager/containers`, params).then(res => res.data);
};
// 设置容器配置
export const putContainerListApi = params => {
	return axios.put(`/container_manager/container`, params).then(res => res.data);
};
// 查询容器状态
export const poststatusList = params => {
	return axios.post(`/container_manager/container_status`, params).then(res => res.data);
};
// 容器操作
export const postContainerOperation = params => {
	return axios.post(`/container_manager/container`, params).then(res => res.data);
};
// 容器上传获取handle和stream_id
export const postFileParameter = params => {
	return axios.post(`/device_maintenance/upload_file`, params).then(res => res.data);
};
// 容器停止上传
export const deleteFileParameter = params => {
	return axios.delete(`/device_maintenance/upload_file`, { data: params }).then(res => res.data);
};
// ------------------------------------------ 数据对接---------------------------------
// 获取GAT1400Server配置
export const getServersga = params => {
	return axios.get(`/device_parameter/gat1400_server_param`, params).then(res => res.data);
};
// 获取GB28181服务端参数
export const getServersgb = params => {
	return axios.get(`/device_parameter/pdns`, params).then(res => res.data);
};
// 设置GAT1400Server配置
export const putServerga = params => {
	return axios.put(`/device_parameter/gat1400_server_param`, params).then(res => res.data);
};
// 获取GAT1400Client配置
export const getClientga = params => {
	return axios.get(`/device_parameter/gat1400_param`, params).then(res => res.data);
};
// 设置GAT1400Client配置
export const putClientga = params => {
	return axios.put(`/device_parameter/gat1400_param`, params).then(res => res.data);
};
// 获取GB28181服务端参数
export const postServersgb = params => {
	return axios.post(`/device_parameter/gb28181_server`, params).then(res => res.data);
};
// 设置GB28181服务端参数
export const putServersgb = params => {
	return axios.put(`/device_parameter/gb28181_server`, params).then(res => res.data);
};
// 获取GB28181Client参数
export const getClientgb = params => {
	return axios.get(`/device_parameter/gb28181_param`, params).then(res => res.data);
};
// 设置GB28181Client参数
export const putClientgb = params => {
	return axios.put(`/device_parameter/gb28181_param`, params).then(res => res.data);
};
// 设置上联设备通道信息
export const putClientequipment = params => {
	return axios.put(`/device_parameter/upper_device_info`, params).then(res => res.data);
};
// 获取上联设备通道信息
export const postClientequipment = params => {
	return axios.post(`/device_parameter/upper_device_info`, params).then(res => res.data);
};
// 获取PDNS配置
export const getPdns = params => {
	return axios.get(`/device_parameter/pdns`, params).then(res => res.data);
};

// 设置PDNS配置
export const putPdns = params => {
	return axios.put(`/device_parameter/pdns`, params).then(res => res.data);
};
// 获取私有SDK配置
export const postSdk = params => {
	return axios.post(`/login_manager/active_param/query`, params).then(res => res.data);
};
// 设置私有SDK配置
export const putSdk = params => {
	return axios.put(`/login_manager/active_param`, params).then(res => res.data);
};
// --------------------------------报警上报---------------------------------------------------
// 获取FTP配置
export const getFtpConfig = params => {
	return axios.get(`/device_parameter/ftp`, { params }).then(res => res.data);
};
// 设置FTP配置
export const putFtpConfig = params => {
	return axios.put(`/device_parameter/ftp`, params).then(res => res.data);
};
// 测试FTP配置
export const putFtpTestConfig = params => {
	return axios.put(`/device_parameter/ftp_test`, params).then(res => res.data);
};
// 获取HTTP和wensocket的接口
export const postWsHttpConfig = params => {
	return axios.post(`/device_alarm/alarm_push_server/cfg`, params ).then(res => res.data);
};
// 设置HTTP和wensocket的接口
export const putWsHttpConfig = params => {
	return axios.put(`/device_alarm/alarm_push_server`, params ).then(res => res.data);
};

// -------------------------------- 文件管理 ------------------------------------------
// 文件搜索
export const getAudios = params => {
	return axios.post(`/device_maintenance/file_search`, params).then(res => res.data);
};
// 文件删除
export const deletefileManagement = params => {
	return axios.delete(`/device_maintenance/file_delete`, { data: params }).then(res => res.data);
};
// 文件播放
export const postAlarm = params => {
	return axios.post(`/device_maintenance/file_ctrl`, params).then(res => res.data);
};

// ------------------------------- 授权管理 --------------------------------------------
// 获取授权文件信息
export const postAuthorization = params => {
	return axios.post(`/intelli_manager/authorization_document`, params).then(res => res.data);
};

// 导入授权文件
export const importSimpleFile = params => {
	return axios.put(`/device_maintenance/device/simple_file`, params).then(res => res.data);
};
// 导入授权秘钥
export const postExportSecretKey = params => {
	return axios.post(`/device_maintenance/device/simple_file`, params).then(res => res.data);
};
// 切换授权

export const putswitchAuthorization = params => {
	return axios.post(`/intelli_manager/control`, params).then(res => res.data);
};

// 设置行业状态
export const authfileOperation = params => {
	return axios.put(`/intelli_manager/industry_key/status`, params).then(res => res.data);
}

// ------------------------------- 设备激活 --------------------------------------------
// 获取激活状态
export const getActivation = params => {
	return axios.get(`/auth/activation`, params).then(res => res.data);
};

// 激活鉴权挑战
export const postAuthentication = params => {
	return axios.post(`/auth/activation`, params).then(res => res.data);
};
// 激活设备
export const putDevice = params => {
	return axios.put(`/auth/activation`, params).then(res => res.data);
};

// 开始升级
export const postStartUpgrade = params => {
	return axios.post(`/device_maintenance/upgrade/start`, params).then(res => res.data);
};

// 停止升级
export const postStopUpgrade = params => {
	return axios.post(`/device_maintenance/upgrade/stop`, params).then(res => res.data);
};

// 设置FTP升级配置---自动升级
export const putFtpUpgrade = params => {
	return axios.put(`/device_maintenance/ftp_upgrade_param`, params).then(res => res.data);
};

// 获取FTP升级配置---自动升级
export const postFtpUpgrade = params => {
	return axios.post(`/device_maintenance/ftp_upgrade_param`, params).then(res => res.data);
};

// 查询FTP升级文件
export const postSearchFtpUpgrade = params => {
	return axios.post(`/device_maintenance/ftp_upgrade_filename`, params).then(res => res.data);
};

// FTP升级---立即升级
export const postFtpImmediateUpgrade = params => {
	return axios.post(`/device_maintenance/ftp_upgrade`, params).then(res => res.data);
};

// 设备重启
export const putDeviceReboot = params => {
	return axios.put(`/device_maintenance/device/reboot`, params).then(res => res.data);
};

// 恢复设备
export const putDeviceRestore = params => {
	return axios.put(`/device_maintenance/device/restore`, params).then(res => res.data);
};

// 系统设置操作
export const putSystemConfig = params => {
	return axios.put('/device_maintenance/opt', params).then(res => res.data);
};

// 设置设备信息
export const putDeviceInfo = params => {
	return axios.put('/device_maintenance/device_info', params).then(res => res.data);
};

// 查询设备信息
export const getDeviceInfo = () => {
	return axios.get('/device_maintenance/device_info').then(res => res.data);
};

// 获取端口配置
export const getProtConfig = () => {
	return axios.get('/device_parameter/ports').then(res => res.data);
};

// 设置端口配置
export const putProtConfig = params => {
	return axios.put('/device_parameter/ports', params).then(res => res.data);
};

// 获取网络配置
export const getNetworkInfo = () => {
	return axios.get('/device_parameter/network').then(res => res.data);
};

// 设置网络参数
export const putNetworkParams = params => {
	return axios.put('/device_parameter/network', params).then(res => res.data);
};

// 获取硬件设备状态
export const getHardwareDeviceStatus = () => {
	return axios.get('/transparent_transmission/boxApi/devices').then(res => res.data);
};

// 获取硬件设备状态
export const postHardwareDeviceStatus = params => {
	return axios.post('/transparent_transmission/boxApi/devices', params).then(res => res.data);
};

// 获取登录管理策略
export const getLoginPolicy = () => {
	return axios.get('/login_manager/login_manager_policy').then(res => res.data);
};

// 设置登录管理策略
export const putLoginPolicy = params => {
	return axios.put('/login_manager/login_manager_policy', params).then(res => res.data);
};

// 修改用户
export const putReviseUser = params => {
	return axios.put('/user_manager/user', params).then(res => res.data);
};

// 设置系统时间
export const putSystemTime = params => {
	return axios.put('/device_parameter/system_time', params).then(res => res.data);
};

// 获取系统时间
export const getSystemTime = () => {
	return axios.get('/device_parameter/system_time').then(res => res.data);
};

// 获取NTP配置
export const getNtpConfig = () => {
	return axios.get('/device_parameter/ntp').then(res => res.data);
};

// 设置NTP配置
export const putNtpConfig = params => {
	return axios.put('/device_parameter/ntp', params).then(res => res.data);
};

// 获取心跳服务器
export const getHeartbeat = () => {
	return axios.get('r/device_alarm/ws_report').then(res => res.data);
};

// 获取显示输出配置
export const postShowOutputConfig = params => {
	return axios.post('/media_display/cfg', params).then(res => res.data);
};

// 设置显示输出配置
export const putShowOutputConfig = params => {
	return axios.put('/media_display/cfg', params).then(res => res.data);
};

// 获取RTSP Server参数
export const postRtspServeParams = params => {
	return axios.post('/device_parameter/rtsp_server', params).then(res => res.data);
};

// 配置RTSP Server参数
export const putRtspServeParams = params => {
	return axios.put('/device_parameter/rtsp_server', params).then(res => res.data);
};

// 查询远程设备接入参数
export const postSearchDeviceParams = params => {
	return axios.post(`/device_access/device_config`, params).then(res => res.data);
};

// 修改远程设备接入参数
export const putModifyDeviceParams = params => {
	return axios.put(`/device_access/device_config`, params).then(res => res.data);
};

// 查询算法图片流配置
export const postPictureStreamCfg = params => {
	return axios.post(`/intelli_manager/algo/picture_stream/cfg`, params).then(res => res.data);
};

// 修改算法图片流配置
export const putSetPictureStreamCfg = params => {
	return axios.put(`/intelli_manager/algo/picture_stream/cfg`, params).then(res => res.data);
};

// 获取人员动态特征值参数
export const getFeatureParam = () => {
	return axios.get('/face_manager/update_feature_param').then(res => res.data);
};

// 配置人员动态特征值参数
export const postFeatureParam = params => {
	return axios.post('/face_manager/update_feature_param', params).then(res => res.data);
};

// 添加远程设备
export const postAddDevice = params => {
	return axios.post(`/device_access/device`, params).then(res => res.data);
};

// 删除远程设备
export const deleteDevice = params => {
	return axios.delete(`/device_access/device`, { data: { device_id: params } }).then(res => res.data);
};

// 远程设备流管理
export const postDeviceAccess = params => {
	return axios.post(`/device_access/stream`, params).then(res => res.data);
};

// 获取远程设备状态
export const postDeviceStatus = params => {
	return axios.post(`/device_access/device_state`, params).then(res => res.data);
};

// 远程设备发现
export const postFindDevice = params => {
	return axios.post(`/device_access/search_device`, params).then(res => res.data);
};

// 获取RTSP url
export const postPTSPurl = params => {
	return axios.post(`/device_access/control`, params).then(res => res.data);
};

// 获取外设
export const postPeripherals = params => {
	return axios.post(`/peripheral_device/peripherals`, params).then(res => res.data);
};

// 添加外设
export const postAddPeripheral = params => {
	return axios.post(`/peripheral_device/peripheral`, params).then(res => res.data);
};

// 修改外设
export const putModifyPeripheral = params => {
	return axios.put(`/peripheral_device/peripheral`, params).then(res => res.data);
};

// 删除外设
export const deletePeripheral = params => {
	return axios.delete(`/peripheral_device/peripheral`, { data: { id: params.id, peripheral_type: params.peripheral_type } }).then(res => res.data);
};

// 操作外设
export const putHandlePeripheral = (params) => {
    return axios.put(`/peripheral_device/control`, params).then((res) => res.data)
}
// ------------ 任务配置相关接口 ---------------
// 查询任务接口
export const queryTask = params => axios.post(`/intelli_manager/task_list`, params).then(res => res.data);
// 创建任务接口
export const addTask = params => axios.post(`/intelli_manager/task`, params).then(res => res.data);
// 修改任务接口
export const editTask = params => axios.put(`/intelli_manager/task`, params).then(res => res.data);
// 删除任务接口
export const deleteTask = params => axios.delete(`/intelli_manager/task`, { data: params }).then(res => res.data);

// 添加布控
export const addMonitorConfig = params => axios.post('/intelli_manager/monitor', params).then(res => res.data);
// 修改布控
export const updateMonitorConfig = params => axios.put('/intelli_manager/monitor', params).then(res => res.data);
// 删除布控
export const deleteMonitorConfig = params => {
	return axios.delete('/intelli_manager/monitor', { data: { monitor_id: params.monitor_id} }).then(res => res.data);
}
// 查询布控
export const getMonitorConfig = params => axios.post('/intelli_manager/monitor_list', params).then(res => res.data);


// ------------ 时间计划相关接口 -------------
export const getScheduleList = params => {
	return axios.post('/device_rules/schedule_plan/query', params).then(res => res.data);
};
/*********************** 时间计划 START ****************************/
// 查询时间计划规则
export const querySchedulePlan = params => axios.post(`/device_rules/schedule_plan/query`, params).then(res => res.data);

// 添加时间计划规则
export const addSchedulePlan = params => axios.post(`/device_rules/schedule_plan`, params).then(res => res.data);

// 修改时间计划规则
export const setSchedulePlan = params => axios.put(`/device_rules/schedule_plan`, params).then(res => res.data);

// 删除时间计划规则
export const removeSchedulePlan = params => axios.delete(`/device_rules/schedule_plan`, { data: { schedule_plan_id: params.schedule_plan_id, schedule_plan_type: params.schedule_plan_type } }).then(res => res.data);
/*********************** 时间计划 END ****************************/

// -------------- 运维管理 ----------------
export const postComputerPower = params => {
	return axios.post(`/intelli_manager/computer_power`, params).then(res => res.data);
};
// -------------- 运维管理 end ----------------