// 从localStorage中获取初始值
const storageMenuCollapse = localStorage.getItem('sMenuCollapse')
const storageIsUpdateLogoPic = localStorage.getItem('isUpdateLogoPic')
const storageIsSystemNameChange = localStorage.getItem('isSystemNameChange')
const storageSystemChangeName = localStorage.getItem('systemChangeName')

// 增加 alarm_type 告警类型显示文案map
// 需要一进系统就进行请求，存放到store，进行全局使用

export default {
    namespaced: true,
    state: {
        menuCollapse: storageMenuCollapse ? Boolean(storageMenuCollapse) : false, // 菜单是否打开
        isUpdateLogoPic: storageIsUpdateLogoPic ? Boolean(storageIsUpdateLogoPic) : false,
        isSystemNameChange: storageIsSystemNameChange ? Boolean(storageIsSystemNameChange) : false,
        systemChangeName: storageSystemChangeName ? String(storageSystemChangeName) : '',
        password: '',
        userName: '',
        deviceWay: '',
        hardwareVersion: '',
        videoDevice: 0,
        picDevice: 0,
        relayDevice: 0,
        timeZone: 8,
        keepAliveSwitch: true, // 设备重启或者恢复出厂时无需保活，避免反复弹窗及恢复出厂跳转登录界面
        deviceModel: '',
        deviceTime: "",
        errorPopState: true, // code不为0时弹窗控制，用做批量调取接口时动态关闭
        neutralVersion: false, // 判断是否为中性版本,true-中性版本限制部分内容展示，false-非中性版本正常展示
        alarmConfig: {},// 存放对应的配置
    },
    actions: {
        async updateMenuCollapse({ commit }, value) {
            commit('update_menu_collapse', value)
        },
        async changeIsUpdateLogoPic({ commit }, value) {
            commit('change_is_update_logo_pic', value)
        },
        async changeSystemNameInfo({ commit }, value) {
            commit('change_system_name_info', value)
        },
        async changePassword({ commit }, value) {
            commit('change_password', value)
        },
        async changeUsername({ commit }, value) {
            commit('change_username', value)
        },
        async changeDeviceWay({ commit }, value) {
            commit('change_device_way', value)
        },
        async changeDeviceModel({ commit }, value) {
            commit('change_device_model', value)
        },
        async changeHardwareVersion({ commit }, value) {
            commit('change_hardware_version', value)
        },
        async changeTimeZone({ commit }, value) {
            commit('change_time_zone', value)
        },
        async changedeviceTime({ commit }, value) {
            commit('change_device_time', value)
        },
        async changeKeepAliveSwitch({ commit }, value) {
            commit('change_keep_alive_switch', value)
        },
        async changeErrorPopState({ commit }, value) {
            commit('change_error_pop_state', value)
        },
        async changeAlarmConfig({ commit }, value) {
            commit('change_alarm_config', value);
        }
    },
    mutations: {
        update_menu_collapse(state, value) {
            state.menuCollapse = value
            localStorage.setItem(`sMenuCollapse`, JSON.stringify(value))
        },
        change_is_update_logo_pic(state, value) {
            state.isUpdateLogoPic = value
            localStorage.setItem(`isUpdateLogoPic`, JSON.stringify(value))
        },
        change_system_name_info(state, value) {
            state.isSystemNameChange = value.isSystemNameChange
            state.systemChangeName = value.systemChangeName
            localStorage.setItem(`isSystemNameChange`, JSON.stringify(value.isSystemNameChange))
            localStorage.setItem(`systemChangeName`, value.systemChangeName)
        },
        change_password(state, value) {
            state.password = value
        },
        change_username(state, value) {
            state.userName = value
        },
        change_device_way(state, value) {
            state.deviceWay = value
            state.videoDevice = value * 1  // 视频流设备最大数量
            state.picDevice = value * 2  // 图片流设备最大数量-id从17开始，这里仅仅代表最大新建数量
            state.relayDevice = value * 1 + value * 2  // 继电器设备最大数量
            console.warn('vuex  ===============>', 'videoDevice', state.videoDevice, 'picDevice', state.picDevice);
        },
        change_device_model(state , value){
            state.deviceModel = value
        },
        change_hardware_version(state, value) {
            state.hardwareVersion = value
        },
        change_time_zone(state, value) {
            state.timeZone = value
        },
        change_device_time(state, value) {
            state.deviceTime = value
        },
        change_keep_alive_switch(state, value) {
            state.keepAliveSwitch = value
        },
        change_error_pop_state(state, value) {
            state.errorPopState = value
        },
        change_alarm_config(state, value) {
            state.alarmConfig = value;
        }
    }
}
