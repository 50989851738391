const woekrStr = `   //导出为字符串，方便通过blob转url
function fun(options) {
	const {protocol, host} = options
	let url = ''
	if(host.indexOf('localhost') !== -1) {
		url = protocol + '//' + host + '/megConnectApi/login_manager/keep_alive'
	} else {
		url = protocol + '//' + host + '/login_manager/keep_alive'
	}
	setInterval(() => {
		fetch(url, {
			method: "put",
			headers: {
				"Content-Type": "application/json"
			},
		}).then(res => {
			switch (res.status) {
				case 200:
					res.json().then(data => {
						if(data.code === 512) postMessage({code: 512})
					})

					break;
				case 500:
					postMessage({code: 500})

					break;
				case 404:
					postMessage({code: 404})

					break;
			}
		})
	}, 5000)
}
onmessage = function onmessage(event) {
	if (event) {
		let options = event.data;
		// options = Object.assign(options, {
		//     onSuccess: function onSuccess(e) {
		//         console.log('upload success');
		//         console.log(e);
		//         postMessage({ eventHandle: 'onSuccess', data: {} });
		//     },
		//     onError: function onError(e) {
		//         console.error('upload worker error');
		//         console.log(e);
		//         postMessage({ eventHandle: 'onError', data: {} });
		//     },
		//     onProgress: function onProgress(percent) {
		//         postMessage({ eventHandle: 'onProgress', data: { percent: percent } });
		//     },
		//     onAbort: function onAbort(e) {
		//         console.warn('upload worker abort:');
		//         console.log(e);
		//         postMessage({ eventHandle: 'onAbort', data: {} });
		//     }
		// });
		var request = fun(options);
		// request.send();
	}
}
`;

export default woekrStr;