export default {
    namespaced: true,
    state: {
      device: {
				tz: 27,
				deviceId: "",
				list: 'BlackNameListMode',
				wsMode: 1,
				firmwareVer: '', // 固件版本
				// smartType: -1 // 0-人脸 1-混合
			},
    },
}
