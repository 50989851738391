/**
 * @desc 用于Vue.use的插件类
 *
 * */
import * as Common from './common'

const install =  function (Vue) {
  Vue.prototype.Common = Common
}

export default {
  install,
  Common
}
