// import { cloneDeep, uniq, get } from 'lodash'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    // 可以在多页 tab 模式下显示的页面
    pool: [],
    // 当前显示的多页面列表
    opened: [],
    // 当前页面
    current: '',
    menu: [],

    // 最后一个面包屑
    lastBredCrumbName: '',
    isShowLastBredCrumb: false,

    // 是否触发清除失败库
    isClearFailedList: false,
  },
  actions: {
    /* 
      修改面包屑最后一项方法
      需要传入一个Object：
      lastBredCrumbName 最后一项面包屑的名称
      isShowLastBredCrumb 是否暂时最后一项面包屑
      在打开内部页面的时候需要调用
    */
    async changeLastBredCrumbInfo({ commit }, value) {
      commit('change_last_bred_crumb_info', value)
    },
    async open ({ state, commit, dispatch }, { name, params, query, fullPath }) {
      // 已经打开的页面
      const opened = state.opened
      // 判断此页面是否已经打开 并且记录位置
      let pageOpendIndex = 0
      const pageOpend = opened.find((page, index) => {
        const same = page.fullPath === fullPath
        pageOpendIndex = same ? index : pageOpendIndex
        return same
      })
      if (pageOpend) {
        // 页面以前打开过
        await dispatch('openedUpdate', {
          index: pageOpendIndex,
          params,
          query,
          fullPath
        })
      } else {
        // 页面以前没有打开过
        const page = state.pool.find(t => t.name === name)
        // 如果这里没有找到 page 代表这个路由虽然在框架内 但是不参与标签页显示
        if (page) {
          await dispatch('add', {
            tag: Object.assign({}, page),
            params,
            query,
            fullPath
          })
        }
      }
      // 设置当前的页面
      commit('currentSet', fullPath)
    },
    async add ({ state }, { tag, params, query, fullPath }) {
      // 设置新的 tag 在新打开一个以前没打开过的页面时使用
      const newTag = tag
      newTag.params = params || newTag.params
      newTag.query = query || newTag.query
      newTag.fullPath = fullPath || newTag.fullPath
      // 添加进当前显示的页面数组
      state.opened.push(newTag)
    },
    async openedUpdate ({ state }, { index, params, query, fullPath }) {
      // 更新页面列表某一项
      const page = state.opened[index]
      page.params = params || page.params
      page.query = query || page.query
      page.fullPath = fullPath || page.fullPath
      state.opened.splice(index, 1, page)
    },
    async close ({ state }, { tagName }) {
      // 预定下个新页面
      let newPage = {}
      const isCurrent = state.current === tagName
      // 如果关闭的页面就是当前显示的页面
      if (isCurrent) {
        // 去找一个新的页面
        const len = state.opened.length
        for (let i = 0; i < len; i++) {
          if (state.opened[i].fullPath === tagName) {
            newPage = i < len - 1 ? state.opened[i + 1] : state.opened[i - 1]
            break
          }
        }
      }
      // 找到这个页面在已经打开的数据里是第几个
      const index = state.opened.findIndex(page => page.fullPath === tagName)
      if (index >= 0) {
        // 更新数据 删除关闭的页面
        state.opened.splice(index, 1)
      }
      // 决定最后停留的页面
      if (isCurrent) {
        const { name = 'index', params = {}, query = {} } = newPage
        const routerObj = { name, params, query }
        await router.push(routerObj)
      }
    },
    // 触发清除失败库
    async changeIsClearFailedList({ commit }, value) {
      commit('change_is_clear_failed_list', value)
    },
  },
  mutations: {
    change_last_bred_crumb_info(state, value) {
      state.lastBredCrumbName = value.lastBredCrumbName
      state.isShowLastBredCrumb = value.isShowLastBredCrumb
    },
    change_is_clear_failed_list(state, value) {
      state.isClearFailedList = value
    },
    currentSet (state, fullPath) {
      state.current = fullPath
    },
    initMenu (state, routes) {
      state.menu = routes
    },
    init (state, routes) {
      const pool = []
      const push = function (routes) {
        routes.forEach(route => {
          if (route.children && route.children.length > 0) {
            push(route.children)
          } else {
            if (!route.hidden) {
              const { meta, name, path } = route
              pool.push({ meta, name, path })
            }
          }
        })
      }
      push(routes)
      state.pool = pool
    },
  }
}
