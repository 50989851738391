// 从localStorage中获取初始值
const storageScreenNum = localStorage.getItem('sScreenNum')
const screenNum = storageScreenNum ? parseInt(storageScreenNum, 10) : 1
const videoList = JSON.parse(localStorage.getItem(`megcubeVideoList${screenNum}`)) ||
    new Array(screenNum).fill(null).map(() => ({ videoDragWrap: [] }))

export default {
    namespaced: true,
    state: {
        videoList,
        screenNum: storageScreenNum ? parseInt(storageScreenNum) : 1,
        listLength: [], // 正在录制的视频下标及通道名称数组
        isNeedOver: false, //是否需要结束所有正在录制的视频
    },
    getters: {
        listLength (state) {
            return state.listLength
        },
    },
    actions: {
        async updateVideoList({ commit }, videoList) {
            commit('update_video_list', videoList)
        },
        async updateScreenNum({ commit }, screenNum) {
            commit('update_screen_num', screenNum)
        },
        async updateListLength({ commit }, listLength) {
            commit('update_list_length', listLength)
        },
        async updateIsNeedOver ({commit}, isNeedOver) {
            commit('update_is_need_over', isNeedOver)
        },
    },
    mutations: {
        update_video_list(state, videoList) {
            state.videoList = videoList
            localStorage.setItem(`megcubeVideoList${state.screenNum}`, JSON.stringify(videoList))
        },
        update_screen_num(state, screenNum) {
            state.screenNum = screenNum
            localStorage.setItem('sScreenNum', screenNum.toString());
            // 切换分屏数，更新视频列表
            const videoList = JSON.parse(localStorage.getItem(`megcubeVideoList${screenNum}`)) ||
                new Array(screenNum).fill(null).map(() => ({ videoDragWrap: [] }))
            state.videoList = videoList
            localStorage.setItem(`megcubeVideoList${state.screenNum}`, JSON.stringify(videoList))
        },
        update_list_length(state, listLength) {
            state.listLength = listLength
        },
        update_is_need_over(state, isNeedOver) {
            state.isNeedOver = isNeedOver
        }
    }
}
