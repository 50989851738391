import Vue from 'vue'
import Vuex from 'vuex'


import megCube from './modules/megCube'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    megCube,
  }
})