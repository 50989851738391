export default {
    get(k) {
        return window.localStorage.getItem(`megcube_web_${k}`);
    },
    set(k, v) {
        window.localStorage.setItem(`megcube_web_${k}`, v);
    },
    delete(k) {
        window.localStorage.removeItem(`megcube_web_${k}`);
    }
};
