import Vue from 'vue'
import App from './App.vue'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import Common from './common'
import MEGUI from 'meg-ui'
import EbgUI from 'ebg-ui'
import { message, MegNotification } from 'meg-ui'

import { frameInRoutes } from '@/router/routes'

// 全局样式入口
import '@/styles/index.scss'

Vue.config.productionTip = false

const httpAndHttpsReg = /(http|https):\/\//ig
// 项目WebSocket地址统一修改处
Vue.prototype.$baseIP = process.env.VUE_APP_SERVER_IP.replace(httpAndHttpsReg, '');

Vue.use(MEGUI)
Vue.use(EbgUI) // 业务组件库
// 临时方法
Vue.prototype.$lang = str => str
Vue.use(Common)
// 挂载到$message上
Vue.prototype.$message = message
Vue.prototype.$notification = MegNotification;
//TODO 接口配置暂时使用2.0版本的方式，后续修改
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.withCredentials = true;

// 配置axios
axios.interceptors.request.use(
    function (config) {
        // 获取sessionId用于调用megConnect接口
        let sessionID = sessionStorage.getItem('sessionID')
        if (sessionID) {
            // document.cookie = `sessionID=${sessionID}`
            document.cookie = `sessionID=${sessionID};path=/;`;
        }
        if (config.url === '/NEUTRAL/neutralVersion') {
            config.baseURL = window.location.hostname == 'localhost' ? '/api/v1' : '/v1'
        } else {
            config.baseURL = realBaseURL(JSON.parse(localStorage.getItem('isNeutral')), config.url)
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)
function realBaseURL(isNeutral, url) {
    // TODO 先这么处理兼容之前的固件接口
    if (url.indexOf('v1/') !== -1) {
        // if (isNeutral) {
        //     axios.defaults.baseURL = window.location.hostname == 'localhost' ? '/api/v1/BOX' : '/v1/BOX';
        //     return axios.defaults.baseURL;
        // } else {
        //     axios.defaults.baseURL = window.location.hostname == 'localhost' ? '/api/v1/MEGBOX' : '/v1/MEGBOX';
        //     return axios.defaults.baseURL;
        // }
        axios.defaults.baseURL = window.location.hostname == 'localhost' ? '/api' : '';
        return axios.defaults.baseURL;
    } else if (url.indexOf('/web/webplugin') !== -1) {
        // 请求web plugin 的时候,不做任何处理
    } else {
        axios.defaults.baseURL = window.location.hostname == 'localhost' ? '/megConnectApi' : '';
        return axios.defaults.baseURL;
    }
}
function debounce(fn, wait) {
    let timerId = null
    let flag = true
    return function () {
        clearTimeout(timerId)
        if (flag) {
            fn.apply(this, arguments)
            flag = false
        }
        timerId = setTimeout(() => {
            flag = true
        }, wait)
    }
}
const authError = debounce(contentTips => {
    // Vue.prototype.$alert(contentTips, i18n.t('message.login.error'), {
    //     confirmButtonClass: 'btn-solid',
    //     customClass: 'alert-box',
    //     iconClass: 'alert-icon',
    // });
    Vue.prototype.$error({
        title: i18n.t('message.login.error'),
        content: contentTips,
        footer: null
    })
}, 1500)

let popUpSwitch = true // code512弹窗提示-开关
// 该错误码组件内部处理
const errorCodeBySelf = [
    12,  // 登陆接口，用户名密码错误
]
function clearCookie() {
    var keys = document.cookie.match(/[^ =;]+(?==)/g);
    if (keys) {
        for (var i = keys.length; i--;) {
            document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的,例如：m.kevis.com
            document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的，例如 .m.kevis.com
        }
    }
    if (sessionStorage.getItem('sessionID')) {
        sessionStorage.removeItem('sessionID')
    }
    
    console.log('已清除cookie');
}

let uploadState = false
let time = null

axios.interceptors.response.use(
    function (response) {
        // 获取是否为中性版本接口，阻止弹窗
        if(response.config.url.indexOf('VERSION_TYPE_NEUTRAL') !== -1) return response

        // 过滤通过 webplugin 接口 请求算法仓卡片配置json的情况
        if (response.config.url.indexOf('webplugin') !== -1) {
            return response;
        }

        // 登录态已丢失
        if (response.data.code === 512) {
            // router.push("login");
            window.location.href = `${window.location.origin}/#/login`
            if(popUpSwitch) {
                Vue.prototype.$error({
                    title: i18n.t('message.newError.fail'),
                    content: i18n.t('message.newError.error512'),
                    okText: i18n.t('message.common.confirm')
                })
                popUpSwitch = false
            }
            clearCookie();
            return response
        }

        if(response.config.url.indexOf('intelli_manager/monitor') !== -1 && response.data.code === 1073752826) {
            Vue.prototype.$error({
                title: i18n.t('message.newError.fail'),
                content: i18n.t('message.newError.sameTaskSelectTips'),
                okText: i18n.t('message.common.confirm')
            })
            return response
        }

        // 系统配置-系统时间不得设置在20190801前
        if(response.config.url.indexOf('device_parameter/system_time') !== -1 && response.data.code === 1073741826 && response.data.message === 'FAIL_SYS_TIME_LOW_MIN_20190801_TIME') {
            Vue.prototype.$error({
                title: i18n.t('message.newError.fail'),
                content: i18n.t('message.newError.outTimeRangeTips'),
                okText: i18n.t('message.common.confirm')
            })
            return response
        }

        // 升级中全局loading，等接口正常进行调试
        if (response.data.code === 1073742337 || response.data.code === 513 || response.data.code === 32 || response.data.code === 1073741856) {
            if(uploadState) return
            uploadState = true
            store.state.megCube.common.keepAliveSwitch = false
            store.state.megCube.container.antLoading = true
            store.state.megCube.container.centitle = response.data.code === 32 ? i18n.t('message.newError.restartTips') : i18n.t('message.newError.upgradeTips')
            time = setInterval(() => {
                axios.get('/device_parameter/system_time').then(res => {
                    if(res?.data.code === 0 || res?.data.code === 512) {
                        clearInterval(time)
                        time = null
                        uploadState = false
                        store.state.megCube.common.keepAliveSwitch = true
                        store.state.megCube.container.antLoading = false
                        store.state.megCube.container.centitle = ''
                        location.reload()
                    }
                })
            }, 5000);
            return response
        }
        if(response.config.url.indexOf('activation') !== -1 && response.data.code === 1) return response
        // 如果需要在自己的页面做处理，放在errorCodeBySelf里面
        if(errorCodeBySelf.includes(response.data.code > 22010 ? parseInt(Number(response.data.code).toString(2).slice(7), 2) : response.data.code)
            && response.config.url.indexOf('auth/login') !== -1
        ) return response
        // 人员组
        if(response.data.code === 10009 && response.config.url.indexOf('face_manager/group')) {
            if(response.config.method == 'put') {
                Vue.prototype.$error({
                    title: i18n.t('message.newError.fail'),
                    content: i18n.t('message.newError.groupIsUseTips'),
                    okText: i18n.t('message.common.confirm')
                })
            }
            if(response.config.method == 'delete') {
                Vue.prototype.$error({
                    title: i18n.t('message.newError.fail'),
                    content: i18n.t(`message.newError.error10009`),
                    okText: i18n.t('message.common.confirm')
                })
            }
            return response
        }
        // 升级start接口code10012特殊处理
        if(response.config.url.indexOf('device_maintenance/upgrade/start') !== -1 && response.data.code === 10012) {
            Vue.prototype.$error({
                title: i18n.t('message.newError.fail'),
                content: i18n.t('message.newError.groupImportTips'),
                okText: i18n.t('message.common.confirm')
            })
            return response
        }
        // 数据对接的错误抛出
        if( response.data.code !== 0 && response.config.url.indexOf('gb28181_param') != -1 && response.config.method == 'put'  
            || response.data.code !== 0 && response.config.url.indexOf('gb28181_server') != -1 && response.config.method == 'put'
            || response.data.code !== 0 && response.config.url.indexOf('reports') != -1 && response.config.method == 'post'
            || response.data.code !== 0 && response.config.url.indexOf('httpReports') != -1 && response.config.method == 'post'
            || response.data.code !== 0 && response.config.url.indexOf('gat1400_param') != -1 && response.config.method == 'put'
            ){
            Vue.prototype.$error({
                title: i18n.t('message.newError.fail'),
                content:`${response.data.message == 'GB28181:During registration, do not operate' ? `GB28181: ${i18n.t('message.newError.registeringTips')}` : i18n.t('message.newError.paramFormatError') + response.data.message}`,
                okText: i18n.t('message.common.confirm')
                
            })
            return response
        }
        if (response.data.code == 0 && response.config.url.indexOf('activation') == -1) popUpSwitch = true
        if (response.data.code !== 0 && response.data.code !== 512 && !(response.data instanceof Blob) && response.config.url.indexOf('subscribe') == -1 && store.state.megCube.common.errorPopState) {

            // 登录配置的设置两个接口，错误暂时不做处理
            const notificationUrlList = ['/user_manager/user', '/login_manager/login_manager_policy'];
            const { url , method} = response.config;
            if ((url.indexOf(notificationUrlList[0]) !== -1 || url.indexOf(notificationUrlList[1]) !== -1) && method === 'put') {
                const msg = url.indexOf(notificationUrlList[0]) !== -1 ? i18n.t('message.common.loginAccountFail') : i18n.t('message.common.loginConfigFail');
                Vue.prototype.$notification['error']({
                    message: i18n.t('message.newError.fail'),
                    description: response.data.code === 1073741826 ? `${msg} ${i18n.t('message.newError.invalidParams')}: ${response.data.message}` : i18n.t(`message.newError.error${response.data.code > 22008 ? parseInt(Number(response.data.code).toString(2).slice(7), 2) : response.data.code}`),
                    duration: 1
                });
            } else {
                Vue.prototype.$error({
                    title: i18n.t('message.newError.fail'),
                    content: response.data.code === 1073741826 ? `${i18n.t('message.newError.invalidParams')}: ${response.data.message}` : i18n.t(`message.newError.error${response.data.code > 22008 ? parseInt(Number(response.data.code).toString(2).slice(7), 2) : response.data.code}`),
                    okText: i18n.t('message.common.confirm')
                })
            }
            popUpSwitch = true
            // return Promise.reject(response)
        }
        return response
    },
    function (error) {
        if (error.response) {
            let errRes = error.response
            let ecode = errRes.data.code
            switch (errRes.status) {
                case 400:
                    //过滤人脸批量入库接口'/faces/batch'
                    /*
        if (errRes.config.url !== `${errRes.config.baseURL}/faces/batch`) {
          Vue.prototype.$alert(i18n.t('message.error.e'+ecode) || i18n.t('message.login.unknownCode') + ecode, i18n.t('message.login.error'), {
            confirmButtonClass: 'btn-solid',
            customClass: 'alert-box',
            iconClass: 'alert-icon'
          })
          return false
        }

        if(errRes.config.url == `${errRes.config.baseURL}/configs`){
          break;
        }
        */
                    if (
                        errRes.config.url !== `${errRes.config.baseURL}/faces/batch` &&
                        ecode !== 104618 &&
                        ecode !== 104405
                    ) {
                        if (ecode !== 0) {
                            // 1：N检测人脸失败，在请求里面处理错误消息,1：N模式下不需要提示
                            if (ecode === 104200 && errRes.config.url == `${errRes.config.baseURL}/search`) {
                                return
                            }

                            // if (!util.getErrorCode(ecode)) {
                            //     ecode = 500;
                            // }

                            let contentTips = i18n.t('message.error.e' + ecode)
                            if (ecode === 104001 || ecode === 104002 || ecode === 104201) {
                                switch (ecode) {
                                    case 104001:
                                        if (errRes.config.url == `${errRes.config.baseURL}/devices`) {
                                            let mm = errRes.data.message.split(':')[1]
                                            if (mm.startsWith('e')) {
                                                let netPort = errRes.data.message.indexOf('eth1') == -1 ? '1' : '2'
                                                let params = errRes.data.message.split(' ')[1]
                                                contentTips +=
                                                    ': ' +
                                                    i18n.t('message.deviceSystem.ethnet') +
                                                    netPort +
                                                    ' ' +
                                                    i18n.t('message.deviceSystem.' + params)
                                            } else {
                                                let params = errRes.data.message.split(':')[1]
                                                contentTips += ': ' + i18n.t('message.deviceSystem.' + params)
                                            }
                                            // contentTips += ': '+i18n.t('message.deviceSystem.ethnet')+netPort+' '+ i18n.t('message.deviceSystem.'+ params)
                                        } else {
                                            const errParamName = errRes.data.message.split('ILLEGAL_ARGUMENT:')[1]
                                            switch (errParamName) {
                                                case 'videoNo':
                                                    contentTips += ': ' + i18n.t('message.station.chnName')
                                                    break
                                                case 'pictureNo':
                                                    contentTips += ': ' + i18n.t('message.picStation.chnNo')
                                                    break
                                                case 'URL':
                                                    contentTips += ': ' + i18n.t('message.station.source')
                                                    break
                                                case 'videoName':
                                                    contentTips += ': ' + i18n.t('message.station.chnName')
                                                    break
                                                case 'pictureName':
                                                    contentTips += ': ' + i18n.t('message.picStation.chnName')
                                                    break
                                                case 'deviceId':
                                                    contentTips += ': ' + i18n.t('message.picStation.source')
                                                    break
                                                case 'timeRange':
                                                    contentTips += ': ' + i18n.t('message.station.timeRange')
                                                    break
                                                case 'port':
                                                    contentTips += ': ' + i18n.t('message.picStation.port')
                                                    break
                                                case 'ipAddr':
                                                    contentTips += ': ' + i18n.t('message.picStation.ipAddr')
                                                    break
                                                case 'username':
                                                    contentTips += ': ' + i18n.t('message.picStation.username')
                                                    break
                                                case 'password':
                                                    contentTips += ': ' + i18n.t('message.picStation.password')
                                                    break
                                                case 'deviceSn':
                                                    contentTips += ': ' + i18n.t('message.picStation.SN')
                                                    break
                                                default:
                                                    contentTips += errRes.data.message.split('ILLEGAL_ARGUMENT')[1]
                                                    break
                                            }
                                        }
                                        break
                                    case 104002:
                                        contentTips += errRes.data.message.split('MISSING_ARGUMENT')[1]
                                        break
                                    case 104201:
                                        contentTips += errRes.data.message.split('BAD_QUALITY')[1]
                                        break
                                }
                            }
                            if (ecode == 104509 || ecode == 104506) {
                                let netPort = errRes.data.message.indexOf('eth1') == -1 ? '1' : '2'
                                contentTips += ': ' + i18n.t('message.deviceSystem.ethnet') + netPort
                            }

                            if (ecode == 104508) {
                                contentTips += errRes.data.data.videoNo
                            }
                            if (
                                (errRes.config.url == `${errRes.config.baseURL}/devices` ||
                                    `${errRes.config.baseURL}/NEUTRAL/neutralVersion` ||
                                    `${errRes.config.baseURL}/configs`) &&
                                ecode == 104100
                            ) {
                                router.replace({
                                    path: '/activation'
                                })
                                console.log('activation -- mainjs100')
                                return
                            }
                            if (ecode == 104101) {
                                contentTips += '：' + errRes.data.message.split(':')[2]
                                console.log('activation -- mainjs101')
                                authError(contentTips)
                                return
                            }
                            // Vue.prototype.$alert(contentTips, i18n.t('message.login.error'), {
                            //     confirmButtonClass: 'btn-solid',
                            //     customClass: 'alert-box',
                            //     iconClass: 'alert-icon',
                            // });
                            Vue.prototype.$error({
                                title: i18n.t('message.login.error'),
                                content: contentTips,
                                footer: null
                            })
                        }
                    }

                    // 授权文件管理
                    if (errRes.config.url.endsWith('/authfile/upgrade')) {
                        // Vue.prototype.$alert(i18n.t(`message.authError.e${ecode}`), i18n.t('message.login.error'), {
                        //     confirmButtonClass: 'btn-solid',
                        //     customClass: 'alert-box',
                        //     iconClass: 'alert-icon',
                        // });
                        Vue.prototype.$error({
                            title: i18n.t('message.login.error'),
                            content: i18n.t(`message.authError.e${ecode}`),
                            okText: i18n.t('message.common.confirm')
                        })
                    }
                    if (
                        (errRes.config.url.endsWith('/multiAlert') || errRes.config.url.endsWith('/surveillances')) &&
                        ecode == 104552
                    ) {
                        // Vue.prototype.$alert(i18n.t(`message.error.e${ecode}`), i18n.t('message.login.error'), {
                        //     confirmButtonClass: 'btn-solid',
                        //     customClass: 'alert-box',
                        //     iconClass: 'alert-icon',
                        // });
                        Vue.prototype.$error({
                            title: i18n.t('message.login.error'),
                            content: i18n.t(`message.error.e${ecode}`),
                            footer: null
                        })
                    }
                    break
                case 401:
                    if (
                        errRes.config.url !== `${errRes.config.baseURL}/devices` &&
                        errRes.config.url !== `${errRes.config.baseURL}/configs`
                    ) {
                        // Vue.prototype.$alert(i18n.t('message.login.serverError'), i18n.t('message.login.error'), {
                        //     confirmButtonClass: 'btn-solid',
                        //     customClass: 'alert-box',
                        //     iconClass: 'alert-icon',
                        // });
                        Vue.prototype.$error({
                            title: i18n.t('message.login.error'),
                            content: i18n.t('message.login.serverError'),
                            footer: null
                        })
                    }
                    break
                case 404:
                    // Vue.prototype.$alert(i18n.t('message.login.noAPI'), i18n.t('message.login.error'), {
                    //     confirmButtonClass: 'btn-solid',
                    //     customClass: 'alert-box',
                    //     iconClass: 'alert-icon',
                    // });
                    
                    // 获取中性版本接口不弹窗
                    if (error.config.url.includes('VERSION_TYPE_NEUTRAL')) {
                        break
                    }
                    Vue.prototype.$error({
                        title: i18n.t('message.login.error'),
                        content: i18n.t('message.login.noAPI'),
                        footer: null
                    })
                    break
                case 500:
                    //过滤人脸批量入库接口'/faces/batch'
                    // TODO 暂时把服务端报错的提示框去除
                    // if (errRes.config.url !== `${errRes.config.baseURL}/faces/batch`) {
                    //     Vue.prototype.$error({
                    //         title: i18n.t('message.login.error'),
                    //         content: errRes.data.message ? errRes.data.message : i18n.t('message.login.serverError'),
                    //         footer: null
                    //     })
                    // }
                    break
                default:
                    //过滤人脸批量入库接口'/faces/batch'
                    if (errRes.config.url !== `${errRes.config.baseURL}/faces/batch`) {
                        // Vue.prototype.$alert(i18n.t('message.login.serverError'), i18n.t('message.login.error'), {
                        //     confirmButtonClass: 'btn-solid',
                        //     customClass: 'alert-box',
                        //     iconClass: 'alert-icon',
                        // });
                        Vue.prototype.$error({
                            title: i18n.t('message.login.error'),
                            content: i18n.t('message.login.serverError'),
                            footer: null
                        })
                    }
            }
        } else {
            // 订阅接口报错不展示弹窗
            if (error.config.url.includes('websocket/subscribe')) {
                return
            }
            if (
                error.config.url !== `${error.config.baseURL}/faces/batch` &&
                error.config.url !== `${error.config.baseURL}/devices` &&
                error.config.url !== `${error.config.baseURL}/configs`
            ) {
                // Vue.prototype.$alert(i18n.t('message.login.serverError'), i18n.t('message.login.error'), {
                //     confirmButtonClass: 'btn-solid',
                //     customClass: 'alert-box',
                //     iconClass: 'alert-icon',
                // });
                Vue.prototype.$error({
                    title: i18n.t('message.login.error'),
                    content: i18n.t('message.login.serverError'),
                    okText: i18n.t('message.common.confirm')
                })
            }
        }
        return Promise.reject(error)
    }
)

window.$http = axios

new Vue({
    el: '#app',
    router,
    i18n,
    store,
    components: { App },
    template: '<App/>',
    created() {
        // 采用跟获取算法仓 config.json 一样的方式进行请求
        this.$store.commit('megCube/page/initMenu', frameInRoutes)
        this.$store.commit('megCube/page/init', frameInRoutes)
    }
})

// 判断是否中性版本
axios.get('/version/VERSION_TYPE_NEUTRAL').then(res => {
    if(res.status === 404) store.state.megCube.common.neutralVersion = false
    if(res.status === 200) store.state.megCube.common.neutralVersion = true
}).catch(res => {
    const status = res.message.match(/\d+/g)[0]
    if(status === '404') store.state.megCube.common.neutralVersion = false
})
